<div class="online-task-inspection-form">
  <div class="page-subheader">
    <div class="container">
      <div class="action-row">
        <div class="row pb-5">
          <div class="col-md-12">
            <div class="pull-left">
              <button mat-button routerLink="/online-task-inspection/find-task">
                <mat-icon class="me-2">keyboard_backspace</mat-icon>
                <span>Vyhledat jinou událost</span>
              </button>
            </div>
            <div *ngIf="task && task.auth !== null && task.auth !== undefined && task.auth.expiresIn"
              class="pull-right">
              <div class="row">
                <div class="col" matTooltip="Platnost formuláře.">
                  <button mat-button>
                    <mat-icon class="me-2">timer</mat-icon>
                    <span>{{(task.auth.expiresIn / 60).toFixed(0)}} min.</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row title">
        <div class="col">
          <h1>
            <span class="">Pojistná událost</span>
            <span *ngIf="task && task.claimNumber !== null && task.claimNumber !== undefined && task.claimNumber !== ''"
              class="d-block claim-number">
              č. {{task.claimNumber}}
            </span>
          </h1>
        </div>
        <div *ngIf="showSentAttachmentButton()"
          class="col-12 col-sm-auto align-self-end d-flex justify-content-sm-end pt-3">
          <div class="confirm-online-inspection text-left" (click)="onlineTaskInspectionDone()">
            <mat-icon class="icon material-icons mat-icon">done</mat-icon>
            <span>Odeslat dokumenty</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="task-detail">
    <div *ngIf="task" class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card mat-elevation-z15">
            <div class="row info-row-padding">
              <ngb-alert [type]="'info'" class="d-inline-block" [dismissible]="false" *ngIf="showSentAttachmentButton() === true">
                {{"portal.onlineTaskInspection.info" | translate}}
              </ngb-alert>
            </div>
            <div class="row">
              <div class="col-12 mb-4 mb-md-5 col-xl-4 col-md-6">
                <div class="row">
                  <div class="col-auto">
                    <mat-icon class="icon material-icons mat-icon">assignment</mat-icon>
                  </div>
                  <div class="col ps-4 pt-2">
                    <div class="card-subtitle">Číslo události</div>
                    <div class="card-title">č. {{(task.claimNumber && task.claimNumber !== '') ? task.claimNumber :
                      'neuvedeno'}}</div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 mb-md-5 col-xl-4 col-md-6">
                <div class="row">
                  <div class="col-auto">
                    <mat-icon class="icon material-icons mat-icon">directions_car</mat-icon>
                  </div>
                  <div class="col ps-4 pt-2">
                    <div class="card-subtitle">RZ vozidla</div>
                    <div class="card-title">{{(task.carLicensePlate && task.carLicensePlate !== '') ?
                      task.carLicensePlate : 'neuvedeno'}}</div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 mb-md-5 col-xl-auto col-md-12">
                <div class="row">
                  <div class="col-auto">
                    <mat-icon class="icon material-icons mat-icon">business</mat-icon>
                  </div>
                  <div class="col ps-4 pt-2">
                    <div class="card-subtitle">Pojišťovna</div>
                    <div class="card-title">{{(task.partner && task.partner.name !== null && task.partner.name !==
                      undefined) ? task.partner.name : 'neuvedeno'}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="canUploadFiles() === true">
              <div class="col-12">
                <div class="row">
                  <div class="col-auto">
                    <mat-icon class="icon material-icons mat-icon">attach_file</mat-icon>
                  </div>
                  <div class="col ps-4 pt-1">
                    <div class="card-subtitle">Přiložte dokumenty</div>
                    <div class="upload-form text-center text-muted">
                      <app-online-inspection-upload-photos [task]="task"></app-online-inspection-upload-photos>
                      <p>{{"portal.onlineTaskInspection.fileUploadRequirements" | translate}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="canUploadFiles() === false">
              <div class="card-subtitle">{{"form.validators.cantUploadFilesViaSmsInspection" | translate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>